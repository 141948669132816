/* Position and sizing of burger button */


.bm-burger-button {
    position: fixed;
    width: 60px;
    height: 60px;
    right: 40px;
    top: 60px;
    transform: translate(-50%, -50%);
  }

  .bm-burger-button button:focus {
    outline: none;
    
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    /* background: #F5f5f5; */
    
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-button:hover {
      opacity: 0.5;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #727F8B;
    cursor: pointer;
  }

  .bm-cross-button button:focus {
    outline:none;
    outline-offset: 2px;
  }

  .bm-cross:hover {
    background: #fff;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #e0dee3;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #e0dee3;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b8b8;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    outline: none;
    cursor: pointer;

  }

  .bm-item-list a {
    padding: 0.8em;
  
    span {
      margin-left: 10px;
      font-weight: 700;
    }
  }
  
  .bm-item:focus {
    outline: none;
  }

  .bm-item:hover {
    color: #727F8B;
  }
  
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }